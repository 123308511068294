export const occupationOptions = [
    "filmTv",
    "theatre",
    "advertising",
    "circus",
    "dance",
    "events",
    "music",
    "opera",
    "photography",
    "other",
    "multiple",
];